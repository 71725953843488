<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
		
		<!-- <Banner></Banner> -->
		<ul class="dataUpload">
			<router-link :to="'/simulation/simulation?student_id=' + $route.query.student_id+'&uid='+$route.query.uid + '&type=' + item.type" class="item" v-for="(item,index) in cateList" :key="index">
				<div class="pic">
					<img class="img" :src="item.icon" />
				</div>
				<h2 class="title">{{item.title}}</h2>
			</router-link>
		</ul>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	import Banner from '@/components/Index/components/Banner.vue';
	export default {
		components: {
			Header,
			Banner,
			Footer
		},
		data() {
			return {
				cateList: [
					{
						title: '顺序练习',
						type: 1,
						icon: require('../../../assets/images/icon93.png')
					},
					{
						title: '随机练习',
						type: 2,
						icon: require('../../../assets/images/icon94.png')
					},
					{
						title: '错题练习',
						type: 3,
						icon: require('../../../assets/images/icon95.png')
					},
					{
						title: '收藏练习',
						type: 4,
						icon: require('../../../assets/images/icon96.png')
					}
				]
			}
		},
			computed: {
			uid() {
        		this.$store.commit('saveUid',this.$route.query.uid)
				return  this.$route.query.uid;
			}
		},
		methods: {
			
		},
	};
</script>

<style scoped>
	.dataUpload{
		padding: 0.1rem 0.3rem 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: .3rem;
	}
	.dataUpload .item{
		width: 3.3rem;
		height: 1.8rem;
		border-radius: 0.12rem;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		margin-bottom: 0.4rem;
	}
	.dataUpload .item .pic{
		width: 1.2rem;
		height: 1.2rem;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dataUpload .item .pic .img{
		
	}
	.dataUpload .item .title{
		font-size: 0.28rem;
		color: #333333;
		margin-left: 0.3rem;
	}
</style>