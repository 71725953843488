<template>
	<div class="banner">
		<wd-swipe height="4rem">
			<wd-swipe-item  v-for="(item,index) in banner" :key="index">
				<img :src="item.img" class="swipe-img" />
			</wd-swipe-item>
		</wd-swipe>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				banner: []
			}
		},
		created() {
			this.getBanner();
		},
		methods: {
			// 获取轮播图
			async getBanner() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/other/banner',{params:{ type:1 ,is_mobile:1}});
				console.log(res);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('首页轮播：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var banner = res.data;
					for(var i = 0; i < banner.length; i++){
						if(banner[i]['img'].substring(0,4) != 'http'){
							banner[i]['img']=this.$store.state.http + banner[i]['img'] 
						}
					}
					this.banner = banner;
				} else {
					this.banner = [];
				}
			},
		},
	};
</script>

<style scoped>
	.banner{
		padding:0;
		font-size: 0;

	}
	/deep/ .banner .wd-swipe__indicators{
		bottom: 1rem;
	}
	/deep/ .banner .wd-swipe__indicator{
		width: 0.2rem;
		height: 0.04rem;
		border-radius: 0;
		margin-right: 0.12rem;
	}
	/deep/ .banner .wd-swipe__indicator:last-child{
		margin-right: 0;
	}
	.swipe-img{
		height: 100%;
		width: 100%;
		object-fit: contain;

	}
	.bgimg{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		filter: blur(20px) grayscale(.5);
	}
</style>
